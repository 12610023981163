import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snackBar: {
      mensaje: "",
      color: "",
      tiempoMensaje: 6000,
      mostrar: false
    },
    user: {
      correo: "",
      nombre: ""
    },
    webToken: ""
  },
  mutations: {
    changeCloseSnackBar(state) {
      state.snackBar.mostrar = false;
    },
    changeSnackBarProps(state, dataUser) {
      state.snackBar = dataUser;
    },
    changeToken(state, dataUser) {
      state.webToken = dataUser;
    },
    changeUser(state, dataUser) {
      state.user = dataUser;
    },
    changeCerrarSesion(state) {
      state.user = {
        correo: "",
        nombre: ""
      };
      state.webToken = "";
      state.snackBar = {
        mensaje: "Se ha cerrado con éxito",
        color: "success",
        tiempoMensaje: 6000,
        mostrar: true
      };
    }
  },
  actions: {
    setSnackBarProps(context, DataUser) {
      context.commit("changeSnackBarProps", DataUser);
      setTimeout(() => {
        context.commit("changeCloseSnackBar");
      }, 6000);
    },
    setNewToken(context, dataUser) {
      context.commit("changeToken", dataUser);
    },
    setUser(context, dataUser) {
      context.commit("changeUser", dataUser);
    },
    setCerrarSesion(context) {
      context.commit("changeCerrarSesion");
    }
  },
  modules: {},
  getters: {
    snackBar(state) {
      return state.snackBar;
    },
    user(state) {
      return state.user;
    },
    token(state) {
      return state.webToken;
    }
  },
  plugins: [createPersistedState()]
});
