import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/UserExperience.vue")
  },
  {
    path: "/experiencia-de-usuario",
    name: "userExperience",
    component: () => import("../views/UserExperience.vue")
  },
  {
    path: "/activa-tu-garantia",
    name: "activa-garantias",
    component: () => import("../views/Garantias.vue")
  },
  {
    path: "/garantias",
    name: "garantias",
    component: () => import("../views/FormatoGarantias.vue")
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin/Admin.vue")
  },
  {
    path: "/admin/garantias",
    name: "admin-garantias",
    component: () => import("../views/admin/Garantias.vue")
  },
  {
    path: "/admin/formato-garantia",
    name: "admin-formato-garantia",
    component: () => import("../views/admin/FormatoGarantias.vue")
  },
  {
    path: "/admin/experiencia-usuario",
    name: "admin-experiencia-usuario",
    component: () => import("../views/admin/ExperienciaUsuario.vue")
  },
  {
    path: "/exito",
    name: "exito",
    component: () => import("../views/SuccesPage.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
