<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app color="rgb(255, 255, 255)">
      <v-list-item height="75px">
        <v-list-item-avatar height="48px">
          <v-avatar color="#0062B0" size="40">
            <v-icon large color="white">
              mdi-account
            </v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ user.nombre }}
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="{ name: item.to }"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="NombreUsuario">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="cerrarSesion()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="NombreUsuario"
              >Cerrar sesión</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="#fafafa" dark>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/bajaj.png"
          width="100"
        />
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main
      style="background: linear-gradient(132deg, rgba(0,98,176,1) 0%, rgba(0,166,176,1) 85%); "
    >
      <div style="height: 180px; background-image: url(./img-hero-spare-parts.jpg); background-position: center; background-size: cover;"></div>
      <v-container class="pt-6">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="snackBar.mostrar"
      :color="snackBar.color"
      top
      right
      :timeout="snackBar.tiempoMensaje"
    >
      {{ snackBar.mensaje }}
      <v-btn dark text @click="snackBar.Mostrar = false">
        Cerrar
      </v-btn>
    </v-snackbar>
    <v-footer padless color="white">
      <v-col class="text-center" cols="12">
        Copyright © 2020 - {{ new Date().getFullYear() }}
        <strong>Bajaj.</strong> Todos los derechos reservados.
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    drawer: null,
    admin: false,
    items: [
      {
        title: "Activar garantías",
        icon: "mdi-ticket-percent",
        to: "admin-garantias",
        cerrarSesion: true
      },
      {
        title: "Experiencia de usuario",
        icon: "mdi-clipboard-account",
        to: "admin-experiencia-usuario"
      },
      {
        title: "Garantías",
        icon: "mdi-steering-off",
        to: "admin-formato-garantia"
      }
    ]
  }),

  methods: {
    cerrarSesion() {
      this.$store.dispatch("setCerrarSesion");
      this.$router.push({ name: "admin" });
    }
  },
  watch: {
    $route(to) {
      if (
        to.name === "Home" ||
        to.name === "userExperience" ||
        to.name === "activa-garantias" ||
        to.name === "garantias" ||
        to.name === "admin" ||
        to.name === "exito"
      ) {
        this.drawer = false;
        this.admin = false;
      } else {
        this.drawer = null;
        this.admin = true;
      }
    }
  },
  computed: {
    snackBar() {
      const snackBar = this.$store.getters.snackBar;
      return snackBar;
    },
    user() {
      const user = this.$store.getters.user;
      return user;
    }
  }
};
</script>
